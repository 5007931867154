import Pageable from "app/features/common/model/Pageable";
import Queries from "app/features/common/model/Queries";
import Config from "app/features/common/util/Config";
import {PageableUtils} from "app/features/common/util/PageableUtils";

export default class Utils {
    /**
     * Null safe implementation of toLowerCase
     * @param value
     * @returns {string|null}
     */
    static toLowerCaseSafe(value: string): string | null | undefined {
        if (value) {
            return value.toLowerCase();
        } else {
            return null;
        }
    }

    static booleanToString(value?: boolean): string {
        if (value) {
            return String(value)
        } else {
            return "false"
        }
    }

    static booleanParse(value: string): boolean {
        return value === "true";
    }

    static urlBuilder(
        path: string,
        queries: Queries | null = null,
        pageable: Pageable | null = null
    ) {

        const searchParams = new URLSearchParams();

        if (queries != null) {
            for (const [key, value] of Object.entries(queries)) {
                if (value) {
                    searchParams.set(key, String(value));
                }
            }
        }

        PageableUtils.setPageable(searchParams, pageable);

        return path + "?" + searchParams.toString();
    }

    static deleteKeys(
        searchParams: URLSearchParams,
        ...keys: string[]
    ) {
        for (const key of keys) {
            searchParams.delete(key)
        }
    }

    static getKratosLoginUrl() {
        return Config.loginBaseUrl + "/identity/auth/login?return_to=" + encodeURI(window.location.href)
    }

    static toTitleCase(input: string): string {
        if (input.includes("kWh")) {
            return input;
        }
        // First, replace all snake_case underscores with spaces
        const withSpaces = input.replace(/_/g, " ");

        // Next, find and replace all camelCase with space-separated words
        const withSpacesAndSeparatedCamelCase = withSpaces.replace(/([a-z])([A-Z])/g, "$1 $2");

        // Finally, convert the resulting string to Title Case
        return withSpacesAndSeparatedCamelCase
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    }
}
