import Config from "app/features/common/util/Config";

export default class AuthRepository {

    static getAccessToken(): string | null {
        if (Config.debugServiceToken) {
            return Config.debugServiceToken
        } else {
            return window.localStorage.getItem("access_token");
        }
    }

    static getImpersonationIdentityId(): string | null {
        if (Config.debugIdentityId) {
            return Config.debugIdentityId
        } else {
            return window.localStorage.getItem("identity_id");
        }
    }
}