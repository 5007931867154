import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useSettingsContext} from "lib/settings/hooks/useSettingsContext.ts";
import PowerDisplayMode from "lib/theme/PowerDisplayMode.ts";
import React from "react";

function SettingsPowerDisplayModeSelect() {

    const settingsContext = useSettingsContext();

    const updatePowerDisplayMode = (powerDisplayMode: PowerDisplayMode) => {
        settingsContext.onUpdateField('powerDisplayMode', powerDisplayMode);
    }

    return <Box sx={{padding: 2}}>
        <FormControl fullWidth>
            <InputLabel id="power-display-mode-label">Power Display Mode</InputLabel>
            <Select
                labelId="power-display-mode-label"
                id="power-display-mode"
                value={settingsContext.powerDisplayMode}
                label={"Power Display Mode"}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                    updatePowerDisplayMode(event.target.value as PowerDisplayMode)
                }}>
                <MenuItem value={PowerDisplayMode.Kw}>kW</MenuItem>
                <MenuItem value={PowerDisplayMode.Amps}>Amps</MenuItem>
            </Select>
        </FormControl>
    </Box>
}

export default SettingsPowerDisplayModeSelect;
