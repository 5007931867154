import {Avatar, Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {useUserContext} from "lib/user/hooks/useUserContext";
import React from "react";

function SettingsUserAvatar(
    {
        fullMode,
        onClick
    }: {
        fullMode?: boolean,
        onClick?: () => void | undefined;
    }
) {
    const userContext = useUserContext()
    const user = userContext.currentUser

    let username = user?.displayName;

    if (!username) {
        username = user?.firstName + " " + user?.lastName
    }

    return (
        <Tooltip title={username ?? 'Account'}>
            <Stack sx={{py: 0.5}}>
                <IconButton onClick={onClick}
                            aria-label="Current User"
                            size="small"
                            aria-haspopup="true"
                            sx={{width: 'fit-content', margin: '0 auto'}}>
                    <Avatar src={user?.profileImageUrl}
                            alt={username}
                            sx={{height: fullMode ? 48 : 32, width: fullMode ? 48 : 32}}/>
                </IconButton>
                {fullMode && <>
                    <Box sx={{mt: 1}}/>
                    <Typography textAlign="center">{username}</Typography>
                    <Typography textAlign="center">{user?.email}</Typography>
                </>}
            </Stack>
        </Tooltip>
    )
}

export default SettingsUserAvatar;
