import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useSettingsContext} from "lib/settings/hooks/useSettingsContext";
import React from "react";
import {useTranslation} from "react-i18next";

function SettingsResetButton() {

    const settingsContext = useSettingsContext();
    const {t} = useTranslation();

    const resetToDefaults = () => {
        console.log("resetting to defaults");
        settingsContext.onReset();
    }

    return <Box
        sx={{mt: 1}}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Button onClick={resetToDefaults}>
            {t("settings.reset_to_defaults")}
        </Button>
    </Box>
}

export default SettingsResetButton;
