import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useColorScheme} from '@mui/material/styles';
import React from "react";
import {useTranslation} from "react-i18next";

function SettingsThemeModeSelect() {

    const {mode, setMode} = useColorScheme();
    const {t} = useTranslation();

    return <Box sx={{padding: 2}}>
        <FormControl fullWidth>
            <InputLabel id="theme-mode-label">{t("settings.theme")}</InputLabel>
            <Select
                labelId="theme-mode-label"
                id="theme-mode"
                value={mode}
                label={t("settings.theme")}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                    setMode(event.target.value as 'light' | 'dark' | 'system')
                }}>
                <MenuItem value="light">{t("theme.light")}</MenuItem>
                <MenuItem value="dark">{t("theme.dark")}</MenuItem>
                <MenuItem value="system">{t("theme.system_setting")}</MenuItem>
            </Select>
        </FormControl>
    </Box>
}

export default SettingsThemeModeSelect;
