import MontaResponseDTO from "app/features/common/model/MontaResponseDTO";

export default function getErrorMessage(montaResponse?: MontaResponseDTO): string | undefined {
    if (montaResponse?.localizedMessage) {
        return montaResponse.localizedMessage
    } else if (montaResponse?.localized_message) {
        return montaResponse.localized_message
    } else if (montaResponse?.message) {
        return montaResponse.message
    } else {
        return "Unknown error"
    }
}