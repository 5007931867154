import * as Sentry from "@sentry/react";
import App from "app/App";
import ErrorPage from "app/pages/ErrorPage.tsx";
import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'app/i18n';

AnalyticsUtil.init();

ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
).render(
    <React.StrictMode>
        <Sentry.ErrorBoundary
            fallback={({error}) => {
                return <ErrorPage type="500" error={error}/>
            }}
            onError={(error: unknown, componentStack: string | undefined, eventId: string) => {
                console.log("ErrorBoundary caught an error:", error, componentStack, eventId);
            }}
            showDialog>
            <App/>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);
