import {
  GridSortDirection,
  GridSortItem,
} from "@mui/x-data-grid/models/gridSortModel";
import Constants from "app/features/common/Constants";
import Pageable from "app/features/common/model/Pageable";

export class PageableUtils {
  static getPageableFromSearchParams(
    searchParams: URLSearchParams,
    defaultPageable: Pageable,
  ) {
    const sizeString = searchParams.get(Constants.SIZE_KEY);
    const pageString = searchParams.get(Constants.PAGE_KEY);
    const sortStrings = searchParams.getAll(Constants.SORT_KEY);

    return new Pageable(
      this.parseIntOrDefault(sizeString, defaultPageable.size),
      this.parseIntOrDefault(pageString, defaultPageable.page),
      this.stringToSort(sortStrings) ?? defaultPageable.sort,
    );
  }

  static setPageable(
    searchParams: URLSearchParams,
    pageable: Pageable | null = null,
  ) {
    if (pageable != null) {
      searchParams.set(Constants.SIZE_KEY, pageable.size.toString());
      searchParams.set(Constants.PAGE_KEY, pageable.page.toString());
      const sortString = this.sortToString(pageable);
      if (sortString) {
        searchParams.set(Constants.SORT_KEY, sortString);
      }
    }
  }

  private static parseIntOrDefault(value: string | null, defaultValue: number) {
    if (value == null) {
      return defaultValue;
    }
    try {
      return parseInt(value);
    } catch {
      return defaultValue;
    }
  }

  private static stringToSort(sortStrings: string[]): GridSortItem[] | null {
    const sortItems: GridSortItem[] = [];

    if (sortStrings.length == 0) {
      return null;
    }

    for (const sortString of sortStrings) {
      const sortArray = sortString.split(",");
      if (sortArray.length != 2) continue;
      sortItems.push({
        field: sortArray[0],
        sort: sortArray[1].toLowerCase() as GridSortDirection,
      });
    }

    if (sortItems.length == 0) {
      return null;
    }

    return sortItems;
  }

  private static sortToString(pageable: Pageable): string | null | undefined {
    const sortStrings: string[] = [];

    if (!pageable.sort) {
      return null;
    }

    for (const sortItem of pageable.sort) {
      if (sortItem.sort) {
        sortStrings.push(sortItem.field + "," + sortItem.sort);
      }
    }

    const sort = sortStrings.join(",");

    if (sort) {
      return sort;
    } else {
      return null;
    }
  }
}
