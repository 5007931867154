import MontaAppLayout from "lib/layout/MontaAppLayout.tsx";
import React, {lazy} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

const ChargePage = lazy(() => import('app/pages/ChargePage.tsx'));
const ChargePointPage = lazy(() => import('app/pages/ChargePointPage.tsx'));
const ChargePointsPage = lazy(() => import('app/pages/ChargePointsPage.tsx'));
const ErrorPage = lazy(() => import('app/pages/ErrorPage.tsx'));
const LoadBalancingGroupsPage = lazy(() => import('app/pages/LoadBalancingGroupsPage.tsx'));
const LoadBalancingGroupPage = lazy(() => import('app/pages/LoadBalancingGroupPage.tsx'));

function MontaAppRouter() {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<MontaAppLayout/>}>
                <Route index element={<ChargePointsPage/>}/>
                <Route path="charge-points" element={<ChargePointsPage/>}/>
                <Route path="charge-points/:identity" element={<ChargePointPage/>}/>
                <Route path="charges/:chargeId" element={<ChargePage/>}/>
                <Route path="load-balancing-groups" element={<LoadBalancingGroupsPage/>}/>
                <Route path="load-balancing-groups/:loadBalancingGroupId" element={<LoadBalancingGroupPage/>}/>
                <Route path="/403" element={<ErrorPage type="403"/>}/>
                <Route path="/404" element={<ErrorPage type="404"/>}/>
                <Route path="/500" element={<ErrorPage type="500"/>}/>
                <Route path="*" element={<ErrorPage type="404"/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
}

export default MontaAppRouter;
