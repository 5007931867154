import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export enum ModalType {
    AuthBlock = "auth-block",
    AuthUnblock = "auth-unblock",
    AuthSetPassword = "auth-set-password",
    AuthRemovePassword = "auth-remove-password",
    CommandSetQrCode = "command-set-qr-code",
    Settings = "settings",
}

const useModal = (modalType: ModalType) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const modalName = searchParams.get("modal")
        setIsVisible(modalType === modalName);
    }, [searchParams]);

    const showModal = () => {
        AnalyticsUtil.modalShown(modalType);
        searchParams.set("modal", modalType)
        setSearchParams(searchParams)
    }

    const hideModal = () => {
        searchParams.delete("modal")
        setSearchParams(searchParams)
    }

    return {
        isVisible: isVisible,
        showModal: showModal,
        hideModal: hideModal
    };
};

export default useModal;
