import ErrorUtil from "app/features/common/util/ErrorUtil";
import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import UserApi from "lib/user/api/UserApi";
import UserDTO from "lib/user/model/UserDTO";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";

function useGetUser() {

    const {enqueueSnackbar} = useSnackbar();
    const [currentUser, setCurrentUser] = useState<UserDTO | null>(null)

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const user = await UserApi.getMe();
                AnalyticsUtil.setUser(user);
                setCurrentUser(user);
            } catch (e) {
                ErrorUtil.HandleError(e, enqueueSnackbar);
            }
        };
        void getCurrentUser();
    }, [])

    return {
        currentUser
    };
}

export default useGetUser;
